/**
 * Site nav related functions
 * 
 * Showing/Hiding main nav when scrolling up and down
 * Changing state of main nav on home page (from absolute to not, vice versa)
 */

(function( $ ) {
	
	let $header = $('.js-site-header');
	let headerHidden = false;

	let ScrollVelocity = (function() {
		let _ = this;

		_.scrollDelta = 0;
		_.scrollTop = $(window).scrollTop();

		// the scrolling speed required before a change event is fired
		_.threshold = 10;

		$(window).on( 'scroll', function() {
			// Positive for scrolling down
			// Negative for scrolling up
			_.scrollDelta = $(window).scrollTop() - _.scrollTop;

			if ( 
				_.scrollDelta > 0 || // down will trigger right away
				// up only triggers if above threshold or at top
				(_.scrollDelta < 0 && Math.abs(_.scrollDelta) >= _.threshold) || 
				0 === $(window).scrollTop()
			) {
				$(window).trigger('scrollChange', [_.scrollDelta, _.threshold, _.scrollTop]);
			}

			_.scrollTop = $(window).scrollTop();
			_.scrollDelta = 0;
		} );

		return _;
	})();

	function onScrollChange(event, delta, threshold, scrollTop) {
		if ( !headerHidden && delta > 0 && scrollTop > 80 ) {
			$('body').addClass('hide-header');
			headerHidden = true;

			$(document).trigger('headerChange', [true]); // true == hidden
		}

		if ( headerHidden && (delta < 0 || 0 === scrollTop) ) {
			$('body').removeClass('hide-header');
			headerHidden = false;

			$(document).trigger('headerChange', [false]); // false == shown
		}


		if ( $(window).scrollTop() >= 150 ) {
			$('body').addClass('scrolled-down');
		}

		if ( $(window).scrollTop() < 150 ) {
			$('body').removeClass('scrolled-down');
		}
	}

	onScrollChange();
	$(window).on('scrollChange', onScrollChange);

})( jQuery );
