( function( $, window, document, undefined ) {

	var $button_group = $('.filter-button-group--team');
	var $buttons = $button_group.find('button');

	var $cs_button_group = $('.filter-button-group--case-studies');
	var $cs_buttons = $cs_button_group.find('button');

	var $filter = $('.filter--team').isotope({
		layoutMode: 'fitRows',
		percentPosition: true,
		itemSelector: '.filter-item',
		fitRows: {
			gutter: '.gutter-sizer',
		}
	});

	var $cs_filter = $('.filter--case-studies').isotope({
		layoutMode: 'vertical',
		itemSelector: '.filter-item',
	});


	$button_group.on( 'click', 'button', function(e) {
	  var filterValue = $(this).attr('data-filter');
	  $filter.isotope({ filter: filterValue });
	  $buttons.each( function() {
	  	$(this).removeClass('active');
	  });
	  $(e.target).addClass('active');
	});

	$cs_button_group.on( 'click', 'button', function(e) {
	  var filterValue = $(this).attr('data-filter');
	  $cs_filter.isotope({ filter: filterValue });
	  $cs_buttons.each( function() {
	  	$(this).removeClass('active');
	  });
	  $(e.target).addClass('active');
	});

} )( jQuery, window, document );
