// Sticky section menu

(function( $ ) {

	let $menu = $('.js-section-menu');

	if ( !$menu.length ) {
		return;
	}

	let menuTop = $menu.offset().top;
	let offset = 0;
	// create cloned version of nav to fix to top
	// prevents content jump when scrolling
	let $menuFixed = $menu.clone().addClass('is-fixed').hide();

	function shouldShowFixed() {
		let scrollTop = $(window).scrollTop();

		return scrollTop + offset >= menuTop;
	}

	// remove fixed clone from a11y tree
	$menuFixed.attr('aria-hidden', 'true');
	$menuFixed.attr('role', 'presentation');
	$menuFixed.find('a').attr({
		'tabindex': '-1',
		'aria-hidden': 'true'
	});

	$menu.after($menuFixed);
	$menuFixed.toggle(shouldShowFixed());

	$(window).resize(debounce(function(event) {
		menuTop = $menu.offset().top;

		$menuFixed.toggle(shouldShowFixed());
	}, 150));

	$(window).scroll(throttle(function(event) {
		$menuFixed.toggle(shouldShowFixed());
	}, 30));

	$(document).on('headerChange', function(event, headerVisible) {
		offset = $('.js-site-header').outerHeight() * (1 - headerVisible);
		$menuFixed.css('transform', `translateY(${offset}px)`);
	});
	
})( jQuery );
