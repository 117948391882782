( function( $, window, document, undefined ) {

	$.extend($.featherlight.defaults, {
	  closeOnClick: 'anywhere',
	  afterOpen:  function(){
                animateIn(this);
            },
      beforeClose:  function(){
                animateOut(this);
            },
	});


	function animateIn(v) {
		var element = v.$content;
		element.addClass('fade-in');
	}

	function animateOut(v) {
		var element = v.$content;
		element.removeClass('fade-in');
	}

} )( jQuery, window, document );