(function( $ ) {
	
	const $hamburger = $('.hamburger');
	const $nav = $('#js-site-nav');
	const $body = $('body');

	const disableBodyScroll = bodyScrollLock.disableBodyScroll;
	const enableBodyScroll = bodyScrollLock.enableBodyScroll;
	const targetElement = document.querySelector('#js-site-nav ul');

	$hamburger.on('click', () => {
		if ( $nav.hasClass('is-open') ) {
			CloseMenu();
		} else {
			OpenMenu();
		}
	});

	function OpenMenu() {
		$hamburger.addClass('is-active');
		$nav.addClass('is-open');
		disableBodyScroll(targetElement);
		$body.addClass('has-open-menu');
	}

	function CloseMenu() {
		$hamburger.removeClass('is-active');
		$nav.removeClass('is-open');
		enableBodyScroll(targetElement);
		$body.removeClass('has-open-menu');
	}

})( jQuery );
