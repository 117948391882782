( function( $, window, document, undefined ) {

	var $glide_slider = $('.glide-slider');

	if ( $glide_slider.length ) {

		var glide = new Glide('.glide', {
			type: 'slider',
			startAt: 0,
			perView: 1,
			gap: 150,
			animationDuration: 800,
			peek: {
				before: 0,
				after: 0
			},
			focusAt: 'center',
			breakpoints: {
				1920: {
					gap: 100,
				},
				1500: {
					gap: 80,
				},
				1200: {
					gap: 60
				},
				991: {
					gap: 50 
				},
				767: {
					focusAt: 0,
					gap: 30,
					peek: {
						before: 0,
						after: 50
					}
				}
			}
		});

		glide.mount();
	
	}

} )( jQuery, window, document );
